import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardDetails = ({
  selectedProduct,
  onCardError,
  onCardSuccess,
  styles,
  paymentDisabled,
  onSubmit,
  currency,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [showSpinner, setShowSpinner] = useState(false);

  if (selectedProduct === null) return null;

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowSpinner(true);
    stripe.createToken(elements.getElement(CardElement)).then((result) => {
      if (result.error) {
        onCardError(result.error);
        setShowSpinner(false);
      } else {
        const body = {
          amount: selectedProduct.price.toString().replace(".", ""),
          source: result.token.id,
          receipt_email: selectedProduct.email,
          description:
            window._env_.REACT_APP_TIP_RECEIPT_DESCRIPTION ||
            "Support Feral Research!",
        };
        try {
          fetch(`${window._env_.REACT_APP_PAYMENT_API}/stripe/charge`, {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(body),
          }).then((response) => {
            response.json().then((data) => {
              setShowSpinner(false);
              if (!data.charge) {
                onCardError(data);
              } else {
                onCardSuccess(data);
              }
            });
          });
        } catch (error) {
          setShowSpinner(false);
          onCardError(error);
          console.error(error);
        }
      }
    });
  };

  return (
    <div>
      <div className="checkout-form">
        {showSpinner && (
          <div>
            <div
              style={{
                top: 0,
                left: 0,
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                zIndex: 1,
                backgroundColor: "#ffffff",
                opacity: 0.75,
              }}
            >
              <div style={{ margin: "auto" }}>
                <div className="loader">Loading...</div>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <label>
            <div style={styles.inputLabel}>Card details</div>

            <div className="input-text">
              <div>
                <CardElement
                  options={{
                    style: styles.cardElement,
                  }}
                />
              </div>
            </div>
          </label>
          <div style={styles.inputLabel}>
            <div style={{ margin: "auto" }}>
              <button
                disabled={paymentDisabled}
                className="input-button"
                id="stripebutton"
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      margin: "auto",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div onClick={onSubmit}>
                      Pay $
                      {isNaN(selectedProduct.price) ? 0 : selectedProduct.price}{" "}
                      {currency}
                    </div>
                    <div style={{ padding: ".3rem 0 0 1rem" }}>
                      <FontAwesomeIcon icon="credit-card" />
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CardDetails;
