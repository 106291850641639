import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Tip from "components/Tip";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationCircle,
  faCreditCard,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";
library.add(faExclamationCircle, faCreditCard, faReceipt);

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route
            path={window._env_.REACT_APP_TIP_ROUTE || "/tip/feralresearch"}
          >
            <Tip />
          </Route>
          <Route
            path="/"
            component={() => {
              window.location.href =
                window._env_.REACT_APP_REDIRECT || "https://example.com";
              return null;
            }}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
