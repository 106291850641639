import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardDetails from "./CardDetails.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripe = loadStripe(window._env_.REACT_APP_STRIPE_PUBLIC_API_KEY);

const Tip = () => {
  const suggested =
    window._env_.REACT_APP_TIP_SUGGESTED_AMOUNTS || "5.00,10.00,15.00,20.00";
  const defaultSuggestion =
    window._env_.REACT_APP_TIP_SUGGESTED_DEFAULT || "10.00";
  const minimumAmount = "1";
  const currency = window._env_.REACT_APP_TIP_CURRENCY || "USD";
  const headline = window._env_.REACT_APP_TIP_HEADLINE || "Tip Jar";
  const message = window._env_.REACT_APP_TIP_MESSAGE || "Thank You";
  const chargeCredit = window._env_.REACT_APP_TIP_BILLING_AGENT || "Stripe";
  const allowOther = window._env_.REACT_APP_TIP_ALLOW_OTHER || "true";
  const [receiptUrl, setReceiptUrl] = useState();
  const [selectedAmount, setSelectedAmount] = useState(defaultSuggestion);
  const [cardError, setCardError] = useState();
  const [email, setEmail] = useState();

  const onCardError = (err) => {
    console.error(err);
    setCardError(err);
  };

  const onChangeAmount = (event) => {
    setCardError();
    setSelectedAmount(parseFloat(event.target.value).toFixed(2));
  };

  const onDeselectAll = (event) => {
    setSelectedAmount(0);
    Array.from(document.getElementsByClassName("radio-button")).forEach(
      (el) => (el.checked = false)
    );
  };

  const paymentDisabled =
    isNaN(selectedAmount) || selectedAmount < minimumAmount ? true : false;

  if (receiptUrl) {
    return (
      <div style={styles.wrapper}>
        <div style={{ ...styles.container, height: "20rem", display: "flex" }}>
          <div
            style={{ display: "flex", flexDirection: "column", margin: "auto" }}
          >
            <div style={{ ...styles.headline, textAlign: "center" }}>
              Payment Successful!
            </div>
            <div style={{ ...styles.inputLabel, textAlign: "center" }}>
              <b>Charge will appear as "{chargeCredit}"</b>
            </div>
            <div style={{ marginTop: "1rem" }}>You can close this window</div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "auto",
                }}
              >
                <div style={{ display: "flex", marginTop: "2rem" }}>
                  <button
                    disabled={paymentDisabled}
                    className="input-button"
                    onClick={() => window.open(receiptUrl, "_blank")}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          margin: "auto",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div>View Reciept</div>
                        <div style={{ padding: ".3rem 0 0 1rem" }}>
                          <FontAwesomeIcon icon="receipt" />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.container}>
        {cardError && (
          <div style={styles.error}>
            <div style={{ margin: "0 .5rem" }}>
              <FontAwesomeIcon icon="exclamation-circle" />
            </div>
            <div>{cardError.message}</div>
          </div>
        )}
        <React.Fragment>
          <div style={styles.headline}>{headline}</div>
          <div style={styles.message}>{message}</div>

          <div className="radio-tile-group">
            {suggested.split(",").map((amount) => {
              return (
                <div
                  key={amount}
                  className="input-container"
                  style={styles.inputContainer}
                >
                  <input
                    id={amount}
                    className="radio-button"
                    type="radio"
                    name="amountradio"
                    value={amount}
                    defaultChecked={defaultSuggestion === amount}
                    onClick={onChangeAmount}
                  />
                  <div className="radio-tile">
                    <label htmlFor={amount} className="radio-tile-label">
                      ${amount}
                    </label>
                  </div>
                </div>
              );
            })}

            {allowOther === "true" && (
              <div className="input-container" style={styles.inputContainer}>
                <input
                  type="number"
                  name="otheramount"
                  id="otheramount"
                  onClick={onDeselectAll}
                  onInput={onChangeAmount}
                  placeholder="Other"
                />
              </div>
            )}
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: "60%",
              marginTop: "20px",
              marginBottom: "3px",
            }}
          ></p>
          <div id="payment-request-button" style={{ margin: "0,10px" }}></div>
          <div
            id="paymentbuttons"
            style={{ opacity: paymentDisabled ? 0.5 : 1 }}
          ></div>
        </React.Fragment>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={styles.inputLabel}>Email (For Reciept. Optional.)</div>
          <div>
            <input
              onChange={(event) => {
                setEmail(event.currentTarget.value);
                setCardError("");
              }}
              className={"input-text"}
              type="text"
              placeholder="Email address"
            />
          </div>
        </div>
        <Elements stripe={stripe}>
          <CardDetails
            paymentDisabled={paymentDisabled}
            currency={currency}
            styles={styles}
            selectedProduct={{ price: selectedAmount, email }}
            onCardError={onCardError}
            onCardSuccess={(data) => setReceiptUrl(data.charge.receipt_url)}
          />
        </Elements>
        <div style={{ ...styles.inputLabel, textAlign: "center" }}>
          <b>Charge will appear as "{chargeCredit}"</b>
        </div>
      </div>
    </div>
  );
};

export default Tip;

const styles = {
  headline: {
    textAlign: "left",
    fontWeight: 900,
    fontSize: "2rem",
  },
  message: {
    textAlign: "left",
    margin: "1rem 0 2rem 0",
  },
  error: {
    position: "absolute",
    width: "100%",
    minHeight: "2rem",
    background: "#c34c4c",
    top: 0,
    left: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    fontSize: "1.5rem",
    color: "white",
  },
  wrapper: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
  },
  container: {
    width: "100%",
    maxWidth: "30rem",
    margin: "auto",
    border: "1px solid black",
    padding: "5rem",
    position: "relative",
    textAlign: "center",
  },
  inputLabel: {
    marginTop: "1rem",
    textAlign: "left",
  },
  inputContainer: { marginRight: 0, color: "#4ebd05" },
  cardElement: {
    base: {
      fontSize: "20px",
      color: "#4ebd05",
      "::placeholder": {
        color: "#aaaaaa",
        fontSize: "20px",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
